import { useDispatch, useSelector } from 'react-redux';
import { StateModel } from '../store/state-model';
import { feedbackExperience, setRatingClosed } from '../store/actions/rating';
import { RatingState } from '../store/reducers/rating';
import { useTrackingEvents } from './use-tracking-events';

type RateData = Required<Pick<RatingState, 'rating' | 'commentary'>>;

const useRatingReady = () => {
  const trackingEvents = useTrackingEvents();
  return trackingEvents.some((ev) => ev.code as any === 12);
};

export function useRating() {
  const ratingState = useSelector((state: StateModel) => state.rating);
  const hasRatingEvent = useRatingReady();
  const dispatch = useDispatch();
  const isReadyToRate =
    hasRatingEvent && ((ratingState.rating! < 3 && !ratingState.commentary) || !ratingState.rating);

  const data = {
    rating: ratingState.rating,
    commentary: ratingState.commentary,
  };

  const rate = (data: RateData) => dispatch(feedbackExperience(data));

  const close = () => dispatch(setRatingClosed(true));

  return {
    isLoading: ratingState.isLoading,
    isLoaded: ratingState.isLoaded,
    isSuccess: ratingState.isSuccess,
    isClosed: ratingState.isClosed,
    error: ratingState.error,
    isReadyToRate,
    data,
    rate,
    close,
  };
}
