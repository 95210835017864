import React from 'react';

type Props = {
  fill?: string;
};

export function CheckIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="check-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4189 2.08279C14.2803 1.12989 11.891 0.893831 9.60729 1.4098C7.32359 1.92577 5.26787 3.16613 3.7467 4.94589C2.22554 6.72565 1.32044 8.94945 1.16639 11.2856C1.01235 13.6218 1.6176 15.9452 2.8919 17.9093C4.1662 19.8734 6.04125 21.373 8.23742 22.1843C10.4336 22.9957 12.8332 23.0754 15.0784 22.4116C17.3236 21.7478 19.294 20.376 20.6958 18.5008C22.0977 16.6256 22.8558 14.3475 22.8571 12.0062V10.9551C22.8571 10.6395 23.113 10.3837 23.4286 10.3837C23.7442 10.3837 24 10.6395 24 10.9551V12.0065C23.9985 14.5942 23.1606 17.1125 21.6112 19.1851C20.0618 21.2576 17.8839 22.7738 15.4024 23.5075C12.9209 24.2412 10.2687 24.1531 7.84136 23.2564C5.41402 22.3596 3.34158 20.7022 1.93315 18.5313C0.524719 16.3605 -0.144251 13.7925 0.0260121 11.2104C0.196275 8.62834 1.19665 6.17046 2.87793 4.20335C4.55922 2.23625 6.83134 0.865327 9.35542 0.295044C11.8795 -0.27524 14.5203 -0.0143271 16.884 1.03887C17.1723 1.16731 17.3018 1.50513 17.1734 1.7934C17.0449 2.08167 16.7071 2.21123 16.4189 2.08279ZM23.8324 2.45944C24.0557 2.68248 24.0559 3.04429 23.8328 3.26756L12.4043 14.7076C12.2971 14.8148 12.1517 14.8751 12.0001 14.8751C11.8485 14.8752 11.7031 14.815 11.5959 14.7078L8.16737 11.2792C7.94421 11.056 7.94421 10.6942 8.16737 10.4711C8.39052 10.2479 8.75233 10.2479 8.97549 10.4711L11.9998 13.4954L23.0243 2.45984C23.2473 2.23657 23.6092 2.23639 23.8324 2.45944Z"
        fill={props.fill}
      />
    </svg>
  );
}

CheckIcon.defaultProps = {
  fill: '#108542',
};
