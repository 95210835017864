export interface RatingState {
  isLoading: boolean;
  isLoaded: boolean;
  isSuccess?: boolean;
  isClosed?: boolean;
  rating?: 1 | 2 | 3 | 4 | 5;
  commentary?: string;
  error?: Error;
}

const initialState: RatingState = {
  isLoading: false,
  isLoaded: false,
};

export type RatingAction =
  | { type: 'SET_RATING_VALUE'; payload: RatingState['rating'] }
  | { type: 'SET_RATING_COMMENTARY'; payload: string }
  | { type: 'SET_RATING_LOADING'; payload: boolean }
  | { type: 'SET_RATING_LOADED'; payload: boolean }
  | { type: 'SET_RATING_SUCCESS'; payload: boolean }
  | { type: 'SET_RATING_CLOSED'; payload: boolean }
  | { type: 'SET_RATING_ERROR'; payload: Error };

export default function rating(state = initialState, action: RatingAction): RatingState {
  switch (action.type) {
    case 'SET_RATING_VALUE':
      return { ...state, rating: action.payload };
    case 'SET_RATING_COMMENTARY':
      return { ...state, commentary: action.payload };
    case 'SET_RATING_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_RATING_LOADED':
      return { ...state, isLoaded: action.payload };
    case 'SET_RATING_SUCCESS':
      return { ...state, isSuccess: action.payload };
    case 'SET_RATING_CLOSED':
      return { ...state, isClosed: action.payload };
    case 'SET_RATING_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
