import { RatingAction, RatingState } from '../reducers/rating';
import { AsyncRateExperienceAction } from '../sagas/rating';

export const setRatingValue = (value: 1 | 2 | 3 | 4 | 5): RatingAction => ({
  type: 'SET_RATING_VALUE',
  payload: value,
});

export const setRatingCommentary = (commentary: string): RatingAction => ({
  type: 'SET_RATING_COMMENTARY',
  payload: commentary,
});

export const setRatingLoading = (loading: boolean): RatingAction => ({
  type: 'SET_RATING_LOADING',
  payload: loading,
});

export const setRatingLoaded = (loaded: boolean): RatingAction => ({
  type: 'SET_RATING_LOADED',
  payload: loaded,
});

export const setRatingSuccess = (success: boolean): RatingAction => ({
  type: 'SET_RATING_SUCCESS',
  payload: success,
});

export const setRatingError = (error: Error): RatingAction => ({
  type: 'SET_RATING_ERROR',
  payload: error,
});

export const setRatingClosed = (isClosed: boolean): RatingAction => ({
  type: 'SET_RATING_CLOSED',
  payload: isClosed,
});

export const feedbackExperience = (
  rate: Required<Pick<RatingState, 'rating' | 'commentary'>>
): AsyncRateExperienceAction => ({
  type: 'ASYNC_RATING_RATE_EXPERIENCE',
  payload: rate,
});
