const colors = {
  appBackground: '#f9f9f9',
  appBorder: 'transparent',
  titleForeground: '#2C2929',
  textForeground: '#686868',
  descriptionForeground: '#686868',
  lightGray: '#BDBDBD',
  superLightGray: '#eaeaea',
  buttonForeground: '#fff',
  green: '#108542',
  royal: '#134CD8',
  red: '#E12B36',
  orange: '#FF9900',
  purple: '#7041EE',
  divider: '#E3E4E6',

  // input
  inputBackground: '#F9F9F9',
  inputColor: '#2C2929',

  // social colors
  whatsapp: '#219653',
  facebook: '#2F55A4',
  telegram: '#0088CC',
  sms: '#BDBDBD',

  // heart (aftersale)
  heartLightGray: '#BEBEBE',
  heartGray: '#9B9B9B',
};

export default colors;
