import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  fill?: string;
};

export function VeryHappyFaceIcon(props: Props) {
  const { t } = useTranslation();

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={t('ARIA_LABEL_VERY_HAPPY_FACE')}
      data-testid="very-happy-face-icon"
    >
      <path
        d="M34.0873 29.4834C33.6574 29.0984 32.9969 29.136 32.6139 29.5648C30.4372 31.9972 27.2984 33.3913 24 33.3913C20.7026 33.3913 17.5628 31.9972 15.3861 29.5648C15.001 29.136 14.3426 29.0984 13.9127 29.4834C13.4828 29.8674 13.4473 30.5269 13.8313 30.9568C16.4035 33.8306 20.1099 35.4782 24 35.4782C27.8911 35.4782 31.5976 33.8295 34.1687 30.9568C34.5527 30.5269 34.5162 29.8674 34.0873 29.4834Z"
        fill={props.fill ?? 'black'}
      />
      <path
        d="M24 0C10.7666 0 0 10.7666 0 24C0 37.2334 10.7666 48 24 48C37.2334 48 48 37.2334 48 24C48 10.7666 37.2334 0 24 0ZM24 45.913C11.9165 45.913 2.08696 36.0835 2.08696 24C2.08696 11.9165 11.9165 2.08696 24 2.08696C36.0835 2.08696 45.913 11.9165 45.913 24C45.913 36.0835 36.0835 45.913 24 45.913Z"
        fill={props.fill ?? 'black'}
      />
      <path
        d="M32.3479 16.6956C29.471 16.6956 27.1305 19.0361 27.1305 21.9129C27.1305 22.4889 27.598 22.9564 28.174 22.9564C28.75 22.9564 29.2174 22.4889 29.2174 21.9129C29.2174 20.187 30.622 18.7825 32.3479 18.7825C34.0738 18.7825 35.4783 20.187 35.4783 21.9129C35.4783 22.4889 35.9458 22.9564 36.5218 22.9564C37.0978 22.9564 37.5653 22.4889 37.5653 21.9129C37.5653 19.0361 35.2248 16.6956 32.3479 16.6956Z"
        fill={props.fill ?? 'black'}
      />
      <path
        d="M18.7826 21.9129C18.7826 22.4889 19.2501 22.9564 19.8261 22.9564C20.4021 22.9564 20.8696 22.4889 20.8696 21.9129C20.8696 19.0361 18.5291 16.6956 15.6522 16.6956C12.7753 16.6956 10.4348 19.0361 10.4348 21.9129C10.4348 22.4889 10.9023 22.9564 11.4783 22.9564C12.0543 22.9564 12.5218 22.4889 12.5218 21.9129C12.5218 20.187 13.9263 18.7825 15.6522 18.7825C17.3781 18.7825 18.7826 20.187 18.7826 21.9129Z"
        fill={props.fill ?? 'black'}
      />
    </svg>
  );
}
