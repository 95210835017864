import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { RatingState } from '../reducers/rating';
import { StateModel } from '../state-model';
import {
  setRatingError,
  setRatingLoaded,
  setRatingLoading,
  setRatingSuccess,
} from '../actions/rating';
import endpoints from '../../config/endpoints';

export type AsyncRateExperienceAction = {
  type: 'ASYNC_RATING_RATE_EXPERIENCE';
  payload: Required<Pick<RatingState, 'rating' | 'commentary'>>;
};

export function* asyncRateExperience(action: AsyncRateExperienceAction) {
  const xhost = yield select((state: StateModel) => state.app.xhost);
  const baseUrl = yield select((state: StateModel) => state.app.server);
  const { order, confirmation } = yield select((state: StateModel) => ({
    order: state.app.order,
    confirmation: state.app.confirmation,
  }));

  try {
    yield put(setRatingLoading(true));

    yield call(feedbackMutation, {
      baseUrl,
      xhost,
      order,
      confirmation,
      payload: action.payload,
    });

    yield put(setRatingSuccess(true));
  } catch (err) {
    yield put(setRatingError(err));
    yield put(setRatingSuccess(false));
  } finally {
    yield put(setRatingLoading(false));
    yield put(setRatingLoaded(true));
  }
}

function feedbackMutation({
  baseUrl = '',
  xhost = '',
  order = '',
  confirmation = '',
  payload = {},
}) {
  return axios({
    url: `${baseUrl}${endpoints.feedback}?order=${order}&confirmation=${confirmation}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'x-host': xhost,
    },
    data: payload,
  });
}
