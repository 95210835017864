import React, { ButtonHTMLAttributes } from 'react';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';
import { RatingState } from '../store/reducers/rating';
import { HappyFaceIcon } from './icons/happy-face-icon';
import { NeutralFaceIcon } from './icons/neutral-face-icon';
import { SadFaceIcon } from './icons/sad-face-icon';
import { VeryHappyFaceIcon } from './icons/very-happy-face-icon';
import { VerySadFaceIcon } from './icons/very-sad-face-icon';
import colors from '../resources/colors';

const HIGHLIGHT_COLOR = colors.purple;

type Props = {
  value: RatingState['rating'];
  setValue(value: RatingState['rating']): void;
};

export function RatingFormFacesArray(props: Props) {
  const { t } = useTranslation();
  return (
    <x.div
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      w="100%"
    >
      <RatingItemButton
        onClick={() => props.setValue(1)}
        aria-label={t('VERY_BAD')}
        title={t('VERY_BAD')}
      >
        <VerySadFaceIcon
          fill={props.value === 1 ? HIGHLIGHT_COLOR : undefined}
        />
      </RatingItemButton>
      <RatingItemButton
        onClick={() => props.setValue(2)}
        aria-label={t('BAD')}
        title={t('BAD')}
      >
        <SadFaceIcon fill={props.value === 2 ? HIGHLIGHT_COLOR : undefined} />
      </RatingItemButton>
      <RatingItemButton
        onClick={() => props.setValue(3)}
        aria-label={t('NEUTRAL')}
        title={t('NEUTRAL')}
      >
        <NeutralFaceIcon
          fill={props.value === 3 ? HIGHLIGHT_COLOR : undefined}
        />
      </RatingItemButton>
      <RatingItemButton
        onClick={() => props.setValue(4)}
        aria-label={t('GOOD')}
        title={t('GOOD')}
      >
        <HappyFaceIcon fill={props.value === 4 ? HIGHLIGHT_COLOR : undefined} />
      </RatingItemButton>
      <RatingItemButton
        onClick={() => props.setValue(5)}
        aria-label={t('VERY_GOOD')}
        title={t('VERY_GOOD')}
      >
        <VeryHappyFaceIcon
          fill={props.value === 5 ? HIGHLIGHT_COLOR : undefined}
        />
      </RatingItemButton>
    </x.div>
  );
}

function RatingItemButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <x.button bg="transparent" outline="none" type="button" {...props}>
      {props.children}
    </x.button>
  );
}
