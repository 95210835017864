import React from 'react';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

export function RatingFormFacesLabel() {
  const { t } = useTranslation();
  return (
    <x.div
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      mt="16px"
    >
      <x.span
        fontWeight="500"
        fontSize="9px"
        lineHeight="11px"
        color="gray-500"
      >
        {t('VERY_BAD')}
      </x.span>
      <x.span
        fontWeight="500"
        fontSize="9px"
        lineHeight="11px"
        color="gray-500"
      >
        {t('VERY_GOOD')}
      </x.span>
    </x.div>
  );
}
