import React from 'react';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
  required?: boolean;
  value: string;
  onChange(value: string): void;
};

export function RatingInput(props: Props) {
  const { t } = useTranslation();

  return (
    <x.label
      htmlFor="rating-textarea"
      display="flex"
      flexDirection="column"
      data-testid="rating-input"
    >
      <x.span
        color="gray-700"
        fontStyle="normal"
        fontWeight="bold"
        fontSize="12px"
        lineHeight="16px"
        textTransform="uppercase"
        mb="8px"
      >
        {t('COMMENT')}
      </x.span>
      <x.textarea
        id="rating-textarea"
        placeholder={
          props.required
            ? t('TELL_ABOUT_YOUR_EXPERIENCE')
            : t('TELL_MORE_IF_YOU_WANT')
        }
        rows={3}
        value={props.value}
        onChange={(ev) => props.onChange(ev.target.value)}
        w="100%"
        maxWidth="lg"
        padding="8px"
        borderRadius="4px"
        borderColor="gray-400"
        borderWidth="2px"
      />
    </x.label>
  );
}

RatingInput.defaultProps = {
  required: false,
};
