import { call, delay, put, select } from 'redux-saga/effects';

import {
  setApplicationLoading,
  setApplicationMode,
  setApplicationPoolingInterval,
} from '../actions/app';
import {
  getTrackingEvents,
  setTrackingActions,
  setTrackingEvents,
  setTrackingUnknownCourier,
} from '../actions/tracking';

import { StateModel } from '../state-model';
import { fetchData } from './network';

import endpoints from '../../config/endpoints';
import * as mode from '../../config/constants/mode';

import { IReduxAction } from '../../interfaces/redux-action';
import { ITrackingEvent } from '../../interfaces/tracking-event';
import { setEcommerceActions, setEcommerceProcesses } from '../actions/ecommerce';
import { setStoredSelectedChannels } from '../actions/channel';
import { setRatingCommentary, setRatingValue } from '../actions/rating';

const MINUTE = 60 * 1000;

// eslint-disable-next-line import/prefer-default-export
export function* asyncDoTrackingAction(action: IReduxAction) {
  const order = yield select((state: StateModel) => state.app.order);
  const trackingCode = yield select((state: StateModel) => state.tracking.trackingCode);
  const xhost = yield select((state: StateModel) => state.app.xhost);
  const server = yield select((state: StateModel) => state.app.server);
  const performedAction = action.payload;

  const payload = {
    trackingCode,
    action: performedAction.name,
    informedData: performedAction.informedData ?? null,
  };

  try {
    yield put(setApplicationLoading(true));
    const result = yield call(fetchData, {
      url: `${server + endpoints.action}?order=${order}`,
      method: 'POST',
      payload,
      xhost,
    });

    if (!(result instanceof Error)) {
      yield put(setTrackingEvents([...result.events]));
      yield put(setTrackingActions(result.actions));
    } else {
      yield put({ type: 'ERROR', payload: result.message });
    }
  } catch (error) {
    yield put({ type: 'ERROR', error });
  } finally {
    yield put(setApplicationLoading(false));
  }
}

export function* asyncGetTrackingEvents(action: { type: string; payload: boolean }) {
  const order = yield select((state: StateModel) => state.app.order);
  const confirmation = yield select((state: StateModel) => state.app.confirmation);
  const xhost = yield select((state: StateModel) => state.app.xhost);
  const server = yield select((state: StateModel) => state.app.server);

  yield put(setApplicationLoading(action.payload));
  const response = yield fetchData({
    url: `${server + endpoints.tracking}?order=${order}${
      confirmation ? `&confirmation=${confirmation}` : ''
    }`,
    method: 'GET',
    xhost,
  });

  if (response && !(response instanceof Error)) {
    const { channels, ecommerceProcess, feedback, demo, fastUpdate } = response;
    yield put(setStoredSelectedChannels(channels));

    yield put(setEcommerceProcesses(ecommerceProcess?.events ?? []));
    yield put(setEcommerceActions(ecommerceProcess?.actions ?? []));

    if (demo === true || fastUpdate === true) {
      yield put(setApplicationPoolingInterval(10000));
    }

    if (!!feedback && feedback.rating) {
      yield put(setRatingValue(feedback.rating));
    }

    if (!!feedback && feedback.commentary) {
      yield put(setRatingCommentary(feedback.commentary));
    }

    if (typeof response.trackings === 'object' && response.trackings.length > 0) {
      const { events, actions } = response.trackings[0];

      yield put(setTrackingUnknownCourier(false));

      if (events.length === 1) {
        const unknownCourier = !!events.find((event: ITrackingEvent) => Number(event.code) === 15);

        if (unknownCourier) {
          yield put(setApplicationMode(mode.TRACKING));
          yield put(setTrackingUnknownCourier(true));
        }
      }

      yield put(setTrackingEvents(events));
      yield put(setTrackingActions(actions));
    }

    yield put(setApplicationLoading(false));
  } else {
    yield put({ type: 'ERROR', payload: response.message });
  }
}

export function* startTrackingPooling() {
  while (true) {
    const poolingInterval = yield select((state: StateModel) => state.app.poolingInterval);
    const intervalTime = poolingInterval ?? 10 * MINUTE;

    yield delay(intervalTime);
    yield put(getTrackingEvents(false));
  }
}
