import React from 'react';
import { x } from '@xstyled/styled-components';
import { useRating } from '../hooks/use-rating';
import RatingForm from './rating-form';
import { RatingSuccess } from './rating-success';

export function Rating() {
  const {
    rate,
    data,
    close,
    isSuccess,
    isLoading,
    isClosed,
    isReadyToRate,
  } = useRating();

  if (isClosed || !isReadyToRate) return <></>;

  return (
    <x.section
      display="flex"
      justifyContent="center"
      alignItems="center"
      w="100%"
      bg="gray-50"
      p="20px"
      borderBottomColor="gray-200"
      borderBottomWidth="1px"
      data-testid="rating"
    >
      {!isSuccess && (
        <RatingForm
          rating={data.rating}
          commentary={data.commentary}
          onSubmit={rate}
          disableSubmit={isLoading}
        />
      )}
      {isSuccess && <RatingSuccess onClose={close} />}
    </x.section>
  );
}
