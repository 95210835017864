import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  fill?: string;
};

export function VerySadFaceIcon(props: Props) {
  const { t } = useTranslation();

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={t('ARIA_LABEL_VERY_SAD_FACE')}
      data-testid="very-sad-face-icon"
    >
      <path
        d="M24 29.2173C20.1099 29.2173 16.4045 30.8649 13.8313 33.7387C13.4473 34.1686 13.4838 34.827 13.9127 35.2121C14.1109 35.3905 14.3603 35.4782 14.6087 35.4782C14.8946 35.4782 15.1805 35.3613 15.3861 35.1307C17.5638 32.6983 20.7036 31.3042 24 31.3042C27.2974 31.3042 30.4372 32.6983 32.6139 35.1307C32.9979 35.5606 33.6584 35.5961 34.0873 35.2121C34.5162 34.8281 34.5527 34.1686 34.1687 33.7387C31.5965 30.866 27.8911 29.2173 24 29.2173Z"
        fill={props.fill ?? 'black'}
      />
      <path
        d="M24 0C10.7656 0 0 10.7666 0 24C0 37.2334 10.7656 48 24 48C37.2344 48 48 37.2334 48 24C48 10.7666 37.2344 0 24 0ZM24 45.913C11.9176 45.913 2.08696 36.0835 2.08696 24C2.08696 11.9165 11.9176 2.08696 24 2.08696C36.0824 2.08696 45.913 11.9165 45.913 24C45.913 36.0835 36.0824 45.913 24 45.913Z"
        fill={props.fill ?? 'black'}
      />
      <path
        d="M36.5218 16.6956C35.9448 16.6956 35.4783 17.163 35.4783 17.739C35.4783 19.4649 34.0738 20.8695 32.3479 20.8695C30.622 20.8695 29.2174 19.4649 29.2174 17.739C29.2174 17.163 28.751 16.6956 28.174 16.6956C27.5969 16.6956 27.1305 17.163 27.1305 17.739C27.1305 20.6159 29.471 22.9564 32.3479 22.9564C35.2248 22.9564 37.5653 20.6159 37.5653 17.739C37.5653 17.163 37.0988 16.6956 36.5218 16.6956Z"
        fill={props.fill ?? 'black'}
      />
      <path
        d="M20.8696 17.739C20.8696 17.163 20.4032 16.6956 19.8261 16.6956C19.2491 16.6956 18.7826 17.163 18.7826 17.739C18.7826 19.4649 17.3781 20.8695 15.6522 20.8695C13.9263 20.8695 12.5218 19.4649 12.5218 17.739C12.5218 17.163 12.0553 16.6956 11.4783 16.6956C10.9012 16.6956 10.4348 17.163 10.4348 17.739C10.4348 20.6159 12.7753 22.9564 15.6522 22.9564C18.5291 22.9564 20.8696 20.6159 20.8696 17.739Z"
        fill={props.fill ?? 'black'}
      />
    </svg>
  );
}
