import React from 'react';
import {
  defaultTheme,
  Preflight,
  ThemeProvider,
} from '@xstyled/styled-components';

const theme = {
  ...defaultTheme,
};

export function CustomThemeProvider({
  children,
}: {
  children: React.ReactChildren | React.ReactChild;
}) {
  return (
    <ThemeProvider theme={theme}>
      <Preflight />
      {children}
    </ThemeProvider>
  );
}
