import React, { useState } from 'react';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';
import { RatingState } from '../store/reducers/rating';
import { RatingInput } from './rating-input';
import { RatingFormFacesArray } from './rating-form-faces-array';
import { RatingFormFacesLabel } from './rating-form-faces-label';

type Rating = RatingState['rating'];

type Props = {
  rating?: Rating;
  commentary?: string;
  onSubmit(data: Pick<RatingState, 'rating' | 'commentary'>): void;
  disableSubmit?: boolean;
};

export default function RatingForm(props: Props) {
  const [value, setValue] = useState<Rating>(props.rating);
  const [commentary, setCommentary] = useState(props.commentary ?? '');
  const { t } = useTranslation();

  const disabled = !value || props.disableSubmit;

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (!disabled) props.onSubmit({ rating: value, commentary });
  };

  return (
    <x.form w="100%" maxWidth="lg" onSubmit={handleSubmit}>
      <x.h2
        fontStyle="normal"
        fontWeight="bold"
        fontSize="20px"
        lineHeight="24px"
        textTransform="uppercase"
        color="gray-600"
        mb="24px"
      >
        {t('RATE_YOUR_EXPERIENCE')}
      </x.h2>

      <x.p
        fontStyle="normal"
        fontWeight="normal"
        fontSize="15px"
        lineHeight="18px"
        color="gray-600"
      >
        {t('HOW_YOUR_EXPERIENCE')}
      </x.p>

      <x.div w="100%" mt="32px">
        <RatingFormFacesArray value={value} setValue={setValue} />
        <RatingFormFacesLabel />
      </x.div>

      {!!value && (
        <x.div w="100%" mt="32px">
          <RatingInput
            value={commentary}
            onChange={setCommentary}
            required={value < 3}
          />
        </x.div>
      )}

      {!!value && (
        <x.div
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="100%"
          mt="34px"
        >
          <x.button
            type="submit"
            bg="purple-700"
            color="white"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize="14px"
            lineHeight="16px"
            borderRadius="20px"
            opacity={disabled ? '50%' : '100%'}
            p="10px"
            w="100%"
            maxWidth={{ _: '100%', md: 'md' }}
            cursor={disabled ? 'not-allowed' : 'pointer'}
            disabled={disabled}
            data-testid="submit-rating-button"
          >
            {t('SEND_FEEDBACK')}
          </x.button>
        </x.div>
      )}
    </x.form>
  );
}

RatingForm.defaultProps = {
  disableSubmit: false,
};
