import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  fill?: string;
};

export function NeutralFaceIcon(props: Props) {
  const { t } = useTranslation();

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={t('ARIA_LABEL_NEUTRAL_FACE')}
      data-testid="neutral-face-icon"
    >
      <path
        d="M24 0C10.7666 0 0 10.7666 0 24C0 37.2334 10.7666 48 24 48C37.2334 48 48 37.2334 48 24C48 10.7666 37.2334 0 24 0ZM24 45.913C11.9165 45.913 2.08696 36.0835 2.08696 24C2.08696 11.9165 11.9165 2.08696 24 2.08696C36.0835 2.08696 45.913 11.9165 45.913 24C45.913 36.0835 36.0835 45.913 24 45.913Z"
        fill={props.fill ?? 'black'}
      />
      <path
        d="M15.6522 22.9564C17.3781 22.9564 18.7826 21.5519 18.7826 19.826C18.7826 18.1001 17.3781 16.6956 15.6522 16.6956C13.9262 16.6956 12.5217 18.1001 12.5217 19.826C12.5217 21.5519 13.9262 22.9564 15.6522 22.9564ZM15.6522 18.7825C16.2271 18.7825 16.6956 19.251 16.6956 19.826C16.6956 20.4009 16.2271 20.8695 15.6522 20.8695C15.0772 20.8695 14.6087 20.4009 14.6087 19.826C14.6087 19.251 15.0772 18.7825 15.6522 18.7825Z"
        fill={props.fill ?? 'black'}
      />
      <path
        d="M32.3478 16.6956C30.6219 16.6956 29.2174 18.1001 29.2174 19.826C29.2174 21.5519 30.6219 22.9564 32.3478 22.9564C34.0737 22.9564 35.4783 21.5519 35.4783 19.826C35.4783 18.1001 34.0737 16.6956 32.3478 16.6956ZM32.3478 20.8695C31.7729 20.8695 31.3044 20.4009 31.3044 19.826C31.3044 19.251 31.7729 18.7825 32.3478 18.7825C32.9228 18.7825 33.3913 19.251 33.3913 19.826C33.3913 20.4009 32.9228 20.8695 32.3478 20.8695Z"
        fill={props.fill ?? 'black'}
      />
      <path
        d="M34.4348 29.2173H13.5652C12.9892 29.2173 12.5217 29.6848 12.5217 30.2607C12.5217 30.8367 12.9892 31.3042 13.5652 31.3042H34.4348C35.0108 31.3042 35.4782 30.8367 35.4782 30.2607C35.4782 29.6848 35.0108 29.2173 34.4348 29.2173Z"
        fill={props.fill ?? 'black'}
      />
    </svg>
  );
}
