import React from 'react';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from './icons/check-icon';
import { TimesIcon } from './icons/times-icon';

type Props = {
  onClose(): void;
};

export function RatingSuccess(props: Props) {
  const { t } = useTranslation();

  return (
    <x.section
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      w="100%"
    >
      <x.span aria-label={t('ARIA_LABEL_CHECK_ICON')}>
        <CheckIcon />
      </x.span>

      <x.span
        fontSize="15px"
        px="16px"
        lineHeight="18px"
        color="gray-700"
        w="100%"
      >
        {t('THANKS_FOR_FEEDBACK')}
      </x.span>

      <x.button
        type="button"
        bg="transparent"
        onClick={props.onClose}
        title={t('CLOSE')}
        aria-label={t('ARIA_CLOSE_FEEDBACK_FORM')}
      >
        <TimesIcon />
      </x.button>
    </x.section>
  );
}
